.service-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
    padding: 1rem;
    margin: .5rem;
    background-color: #FFFFFF03;
    border-radius: 30px; 
    font-size: 18px;
    font-weight: 900;
}

.service-card:hover {
    border: 5px solid #29A6DE;
    opacity: 100%;
}


.service-card p {
    opacity: 30%;
}

.service-card:hover p {
    opacity: 100%;
    font-weight: 900;
}

.service-card-icon {
    width: 80px;
    padding-bottom: .8rem;
    opacity: 30%;
    color: #FAFAFA;
}

.service-card-icon-hover {
    display: none;
}

.service-card:hover .service-card-icon {
    opacity: 100%;
    color: #29A6DE;
}

.service-link {
    text-decoration: none;
    color: #FAFAFA;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {
    .service-card {
        width: 40vw;
    }
}

@media screen and (max-width: 768px) {
    .service-card p {
        opacity: 100%
    }
    .service-card-icon {
        width: 70px;
        display: none;
        opacity: 100%;
        color: #29A6DE;
    }

    .service-card-icon-hover {
        display: block;
        width: 55px;
        margin-bottom: 6px;
    }

    .service-card:active {
        border: 5px solid #29A6DE;  
    }

    .service-card:active .service-card-icon {
        opacity: 100%;
    } 
}

@media screen and (max-width: 480px) {
    .service-card {
        width: 11rem;
        height: 11rem;
        padding: 1rem;
        margin: .7rem .5rem .7rem .5rem;
        background-color: #FFFFFF06;
        border-radius: 30px; 
        font-size: 16px;
        font-weight: 900;
    }

}