.subnavigation {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 1rem auto 1rem auto;
    width: 90%;
}

.subnavigation-link {
    margin: .7rem .5rem;
    padding: .5rem 1rem;
    border-radius: 50px;
    text-decoration: none;
    color: #CAEBFF;
    font-weight: 700;
    font-size: 20px;
}

.subnavigation-link:hover {
    background-color: #0093E907;
}

.subnavigation .active {
    /* background-color: linear-gradient(#0093E9, #80D0C7); Tried but failed gradient*/
    background-color: #0093E9;
    color: #FAFAFA;
}

.subnavigation .active:hover {
    background-color: #0093E9;
}

@media screen and (max-width: 480px) {
    .subnavigation-link {
        font-size: 16px;
        padding: .5rem 1.5rem;
    }

}