.skill-list {
    padding-right: 10vw;
    height: auto;
}

.skill-list h2 {
    font-size: 48px;  
    text-align: left;
}

.skill-list ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
    font-size: 22px;
}

.skill-list li {
    display: flex;
    padding-bottom: 2.5rem;
    justify-content: left;
    align-items: center;
    font-size: 20px;
    line-height: 1.5;
}

.skill-list img {
    width: 60px;
    padding-right: 20px;
}
