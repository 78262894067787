.open-card {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    text-align: left;
    height: auto;
    width: 230px;
    box-sizing: border-box;
    margin: 0rem;
}

.open-card ul {
    list-style-type: none;
    padding-left: 0;
    width: 100%
    
}

.open-card ul li {
    padding-bottom: 1rem;
    margin: 0;
    color: #C2C2C2;
}

.open-card h2 {
    font-size: 1.3rem;
    font-weight: 900;
    width: 100%;
    margin: 0;
}

.open-card img {
    width: 60px;
    margin: 0 4rem 2rem 0;
    
}

@media screen and (max-width: 1024px) {
    .open-card {
        width: 230px;
    }

}

@media screen and (max-width: 768px) {
    .open-card {
        width: 190px;
    }
    .open-card h2 {
        font-size: 1.1rem;
    }
    .open-card ul li {
        font-size: .9rem;
    }
}

@media screen and (max-width: 480px) {
    .open-card {
        width: 150px;
    }

}