* {
  box-sizing: border-box;
}

:root {
  --talentic-color: #187cf7;
  --teba-color: #00B2FF;
  --shiga-color: #D62F57;
  --sport1-color: #FAB500;
  --dogtoday-bg-color: #011C0A;
  --tinkersforge-color: #9F48F6;
  --tinkersforge-bg-color: #0F021C;
  --navbar-color: #051A28;
}

.App {
  text-align: center;
  background-color: #001624;
  height: auto;
  margin: 0;
  font-family: "menca", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fafafa;
}

.container {
  text-align: center;
}


