.project-outline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 60%;
  text-align: left;
  padding: 3rem;
  margin: 3rem 0 2rem 0;
  border-radius: 30px;
  background-color: #FFFFFF06;
}

.project-outline ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
    font-size: 22px;
}

.project-outline ul li {
    padding: .5rem 0 .5rem 0;
    font-size: 1.8rem;
    font-weight: 700;
}

.project-outline h2 {
    font-size: 2.4rem;
    font-weight: 900;
    margin: 0 0 1rem 0;
    padding: 0;
}

/* .numbers {
    color: #187cf7;
} */

a {
    color: #FAFAFA;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .project-outline {
        width: 85%;
    }
}

@media screen and (max-width: 768px) {
    .project-outline {
        width: 95%;
        padding: 3rem 2rem 2rem 2rem;
        margin: 4rem 0 2rem 0;
    }
    .project-outline h2 {
        font-size: 1.8rem;
    }
    .project-outline ul li {
        font-size: 1.4rem;
    }
}