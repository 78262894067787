.contact-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 70vh;
    font-size: 22px;
    align-items: start;
    margin-top: 8rem;
}


.contact-section h1 {
    font-size: 72px;
    padding: 0;
    margin: 0 0 .2rem 0;
}

.contact-section h2 {
    font-size: 26px;
    padding: 0;
    margin: 0;
    color: #0093E9;
}

.contact-left-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 10%;
}

.contact-right-column {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: auto;
    padding-top: 11rem;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 3rem;
}

.contact-info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    text-align: left;
}

.contact-info-row img {
    width: 30px;
    margin-right: 1rem;
}

.contact-info h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .contact-section {
        width: 80%;
    }   
}

@media screen and (max-width: 768px) {
    .contact-section {
        display: flex;
        flex-direction: column;
        width: 90%;
    }

    .contact-section h1 {
        font-size: 64px;
        margin: 0;
    }
    
    .contact-section h2 {
        font-size: 24px;
        line-height: 1.5;
        padding: 0;
        margin: 0;
        color: #0093E9;
        text-align: start;
    }
    
}