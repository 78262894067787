.skills-section {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: start;
    margin-bottom: 3rem;
}

.skills-container {
    display: flex;
    flex-direction: row;
}

.skills-subcontainer {
    display: flex;
    flex-direction: column;
}

.skill-list {
    padding-bottom: 0rem;
}

.skill-list .horizontal ul {
    display: flex;
    flex-wrap: wrap;
}

.skill-list .horizontal li {
    width: 33.33%; /* Distribute items into 3 columns */
    box-sizing: border-box;
}
 
@media screen and (max-width: 1920px) {
    .skills-section {
        width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .skills-section {
        width: 90%;
    }
    .skills-container {
        flex-direction: column;
    }
    .skill-list {
        padding-bottom: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .skills-section {
        width: 90%;
    }
    .skills-container {
        flex-direction: column;
    }
}