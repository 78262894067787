.service-overview-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    width: 75%;
    padding: 4rem;
    margin: 3rem 0 3rem 0;
    background-color: #FFFFFF04;
    border-radius: 30px; 
}

.service-overview-container p {
    font-size: 18px;
    line-height: 1.6;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #C2C2C2;

}

.open-cards-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px; /* adjust this value to add space between the cards 
    margin: 5rem 0 0 0; */
}

.service-overview-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
}

.service-overview-icon {
    width: 80px;
    padding-right: 1.5rem;
}

@media screen and (max-width: 1024px) {
    .service-overview-container {
        width: 85%;
        padding: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .service-overview-container {
        width: 100%;
        padding: 2rem;
    }
    .service-overview-container p {
        font-size: 1.2rem;
    }
    .service-overview-icon {
        width: 70px;
        padding-right: 1rem;
    }
    .service-overview-title h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 480px) {
    .service-overview-container {
        width: 100%;
        padding: 2rem;
    }
    .service-overview-container p {
        font-size: 1rem;
    }
}