.home-desc-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-desc-container p {
    width: 60ch;
    font-size: 18px;
    line-height: 1.5;
    color: #C2C2C2;
}

.home-desc-container h1 {
    font-size: 48px;
    padding: 0;
    margin: 0;
}

.home-desc-container h2 {
    font-size: 48px;
    font-family: "samantha-italic", sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 3rem;
    color: #199FE3;
    padding: 0;
    margin: 0;
}

.home-desc-section {
    height: 60vh;
    padding-top: 4rem;
}

@media screen and (max-width: 768px) {
    .home-desc-container p {
        font-size: 16px;
        width: 90%;
    }
    .home-desc-section {
        height: 60vh;
        padding-top: 0rem;
    }

}

@media screen and (max-width: 480px) {

    .home-desc-cta-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: Auto;
    }

    .home-desc-cta-container a .button-primary, .button-secondary {
        width: 80vw;
    }


}