.service-cards-container {
    margin-top: -2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-cards-flexbox {
    display: flex;
    padding: 0;
}

.hero-section {
    padding-top: 6rem;
    height: 100vh;
}

.arrow-down {
    padding: 2rem 0 2rem 0;
}

@media screen and (max-width: 1920px) {
    .media-design-headline {
        width: 700px;
    }
}

@media screen and (max-width: 1024px) {
    .media-design-headline {
        width: 530px;
    }
    .service-cards-flexbox {
        display: block;
        width: Auto;
    }

    .hero-section {
        height: auto;
        padding-bottom: 6rem;
    }
}

@media screen and (max-width: 768px) {
    .media-design-headline {
        width: 470px;
    }
    .arrow-down {
        width: 50px;
        padding: 2rem 0 2rem 0;
    }
}

@media screen and (max-width: 480px) {
    .media-design-headline {
        width: 340px;
    }
    .hero-section {
        padding-top: 5rem;

    }
}