.about-section {
    display: flex;
    flex-direction: row;
    width: 70%;
    height: Auto;
    align-items: start;
    padding: 10rem 0 3rem 0;
}

.about-section h1 {
    font-size: 72px;
    padding: 0;
    margin: 0;
}

.about-section h2 {
    font-size: 26px;
    padding: 0;
    margin: 0;
    color: #0093E9;
}

.about-section p {
    text-align: left;
    color: #C2C2C2;
    width: 70%;
    font-size: 18px;
    line-height: 1.5;
}

.about-section p span {
    font-weight: 700;
}

.about-section img {
    width: 250px;
    border-radius: 10px;
}

.about-text {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-left: 3rem;
}

@media screen and (max-width: 1024px) {
    .about-section {
        width: 80%;
        padding: 6rem 0 3rem 0;
    }
    .about-text {
        margin-left: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .about-section {
        width: 90%;
        flex-direction: column;
        align-items: start;
    }

    .about-text {
        margin: 2rem 0 0 0;
    }

    .about-section p {
        font-size: 16px;
        line-height: 1.7;
    }
    .about-section img {
        display: none;
        border-radius: 10px;
    }
    
}

@media screen and (max-width: 480px) {

    .about-section p {
        width: 100%;
    }
    
    .about-section h1 {
        font-size: 64px;
    }
    
    .about-section h2 {
        font-size: 24px;
        padding: 0;
        margin: 0;
        color: #0093E9;
    }
}