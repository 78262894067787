.service-overview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    width: 75%;
    padding: 2rem;
    margin: 3rem 0 3rem 0;
    background-color: #FFFFFF04;
    border-radius: 30px; 
}

.service-overview-container p {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #C2C2C2;

}

.open-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px; /* adjust this value to add space between the cards */
    margin: 5rem 0 3rem 0;
}

.service-overview-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
}

.service-overview-icon {
    width: 90px;
    padding-right: 1rem;
}

@media screen and (max-width: 1024px) {
    .ui-ux-project section {
        width: 85%;
        padding: 2.5rem;
    } 
}

@media screen and (max-width: 768px) {
    .ui-ux-project section {
        width: 90%;
        padding: 2rem;
    }
    .service-overview-container {
        width: 90%;
    }
    .project-section-container h2 {
        font-size: 24px;
    }
    .segment-columns-icon {
        display: none;
    }
    .segment-columns p {
        font-size: 1rem;
    }
}