.ui-ux-design-project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 8rem;
}

.project-coming-soon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        margin: 0;
}

.project-segment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    margin: 1.5rem 0 1.5rem;
}

.project-segment p {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: .2rem;
    padding: 0;
    color: #C2C2C2;
}

.project-image {
    width: 100%;
    margin: .5rem 0 1rem 0;
}

.ui-ux-design-project section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    border: 4px #FFFFFF08 solid;
    border-radius: 30px;
    padding: 3rem 3rem 1rem 3rem;
    margin-bottom: 1rem;
}

.project-section-container h2 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: left;
}

.project-section-container h3 {
    margin-bottom: 0;
    text-align: left;
}

.segment-columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
}

.segment-columns-icon {
    width: 80px;
    height: 80px;
    margin: 0 0 0 2rem;
    padding: 0;
}

.segment-columns-images {
    margin: 0 .2rem 1rem .2rem;
    width: 50%;
}

.segment-columns p {
    width: 100%;
}

#ui-ux-design-project-1 {
    background-color: #121212;
}

#ui-ux-design-project-2 {
    background-color: #121212;
}

#ui-ux-design-project-3 {
    background-color: var(--tinkersforge-bg-color)
}


.talentic-color {
    color: var(--talentic-color);
    }

.shiga-color {
    color: var(--shiga-color);
}

.tinkersforge-color {
    color: var(--tinkersforge-color);
}

@media screen and (max-width: 1024px) {
    .ui-ux-design-project section {
        width: 85%;
        padding: 2.5rem;
    } 
}

@media screen and (max-width: 768px) {
    .ui-ux-design-project section {
        width: 95%;
        padding: 2rem 1.5rem 0rem 1.5rem;
    }
    .project-section-container h2 {
        font-size: 24px;
        line-height: 1.5;
    }
    .project-section-container h3 {
        line-height: 1.5;
    }
    .segment-columns-icon {
        display:none;
    }
    .segment-columns p {
        font-size: 1rem;
    }
}

