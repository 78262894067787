.project-overview-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.project-overview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    width: 60%;
    padding: 2rem;
    margin-top: 3rem;
    background-color: #FFFFFF06;
    border-radius: 30px;
}

.project-logo {
    height: 40px;
}

.project-preview {
    width: 60%;
    margin-top: 2rem;
}

.project-overview-section h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0 0 1rem 0;
    padding: 0;
}

.project-overview-section p {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color:#C2C2C2;
}

@media screen and (max-width: 1024px) {
    .project-overview-container {
        width: 85%;
    }
}

@media screen and (max-width: 768px) {
    .project-overview-container {
        width: 95%;
    }
    .project-preview {
        width: 80%;
        margin-top: 2rem;
    }
    .project-overview-section p {
        font-size: 1rem;
    }
    .project-logo {
        height: 30px;
    }
}