.graphic-design-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0 0 0;
    text-align: left;
}

.project-coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
}


.graphic-design-project section {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 60%;
margin-bottom: 1rem;
}

.graphic-design-project .project-image {
width: 100%;
padding: 0;
margin: 0;
}

.graphic-design-project p {
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
    font-weight: 400;
    margin-bottom: .3rem;
    padding: 0;
    color: #C2C2C2;
}

.minimal-project-section-container p {
    margin-bottom: 6rem;
}

#graphic-design-project-1 {
background-color: #121212;
}

#graphic-design-project-2 {
background-color: #011C0A;
}

#graphic-design-project-3 {
background-color: #121212;
}

.dogtoday.color {
    color: var(--sport1-color);
}
    
.talentic-color {
    color: var(--talentic-color);
    }

        @media screen and (max-width: 1024px) {
            .graphic-design-project section {
                width: 85%;
                padding: 2.5rem;
            } 
        }
        
        @media screen and (max-width: 768px) {
            .graphic-design-project section {
                width: 90%;
                padding: 2rem;
        
            }
            .project-section-container h2 {
                font-size: 24px;
                line-height: 1.5;
            }
            .project-section-container h3 {
                line-height: 1.5;
            }
            .segment-columns-icon {
                display:none;
            }
            .segment-columns p {
                font-size: 1rem;
            }
        }