.button-primary {
    padding: 1rem;
    margin: .5rem;
    font-size: 18px;
    border-radius: 100px;
    border: none;
    min-width: 200px;
    width: auto;
    background: linear-gradient(to right, #FAFA33, #E7B329);
    font-weight: 700;
    color: #001C2D;
}

.button-primary:hover {
    cursor: pointer;
    background: linear-gradient(to right, #FAFA33, #E7B329);
    transition: linear-gradient 0.5s ease;
    box-shadow: 0 0 14px #FAFA3355;

}

@media screen and (max-width: 480px) {
    .button-primary a {
        width: 100%,
    }
    .button-primary {
        width: 100%,
    }
}