.web-development-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0 0 0;
}

.project-coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
}

.project-segment {
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
text-align: left;
margin-bottom: 3rem;
}

.project-segment p {
font-size: 1rem;
line-height: 1.6;
font-weight: 400;
margin-bottom: .3rem;
padding: 0;
color: #C2C2C2;
}

.project-image {
    width: 100%;
    margin: 1rem 0 2rem 0;
}

.web-development-project section {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 60%;
border: 4px #FFFFFF08 solid;
border-radius: 30px;
padding: 3rem 3rem 1rem 3rem;
margin-bottom: 1rem;
}

.project-section-container h2 {
font-size: 36px;
font-weight: 700;
margin-bottom: 2rem;
text-align: left;
}

.project-section-container h3 {
margin-bottom: 0;
text-align: left;
}

.segment-columns-icon {
width: 80px;
height: 80px;
margin: 0 0 0 2rem;
padding: 0;
}

.segment-columns p {
width: 100%;
}

#web-development-project-1 {
background-color: #121212;
}

#web-development-project-2 {
background-color: #121212;
}

#web-development-project-3 {
background-color: #121212;
}

.teba-color {
    color: var(--teba-color);
    }

.sport1-color {
    color: var(--sport1-color);
}
    
.talentic-color {
    color: var(--talentic-color);
    }

@media screen and (max-width: 1024px) {
    .web-development-project section {
        width: 85%;
        padding: 2.5rem;
    } 
}

@media screen and (max-width: 768px) {
    .web-development-project section {
        width: 95%;
        padding: 2rem 1.5rem 0rem 1.5rem;
    }
    .project-section-container h2 {
        font-size: 24px;
        line-height: 1.5;
    }
    .project-section-container h3 {
        line-height: 1.5;
    }
    .segment-columns-icon {
        display:none;
    }
    .segment-columns p {
        font-size: 1rem;
    }
}