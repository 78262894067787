footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #001E32;
    width: 100%;
    padding: 3rem 0 3rem 0;
    margin: 0;

}

.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 70%;
}

.footer-desc {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-right: 3rem;
}

.footer-desc p {
    width: 30ch;
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
}

.footer-nav {
    display: flex;
    flex-direction: row;
}


.footer-link {
    color: #C2C2C2;
    font-size: 16px;
    text-align: left;
    text-decoration: none;
    padding-bottom: .5rem;
}

.footer-link:hover {
    color:#dfdfdf;
}

.footer-nav ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 4rem 0 0;
}

.footer-nav ul p {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    padding: 0;
    margin: 0 0 1rem 0;
}

.footer-logo {
    width: 150px;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {

    .footer-container {
        width: 80%;
    } 
}

@media screen and (max-width: 768px) {
    .footer-container {
        width: 90%;
        flex-direction: column;
        align-items: left;
    } 
    .footer-nav ul {
        margin-right: 3rem;
    }
    .footer-desc {
        margin-bottom: 1.5rem;
    }
    .footer-desc p {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .footer-container {
        width: 90%;
    }
    .footer{
        padding: 3rem;
    }
    .footer-nav ul {
        margin-right: 4rem;
    }
}