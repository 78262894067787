.thumbnail {
    display: flex;
    flex-direction: column;
    margin: .3rem;
    width: 390px;
    height: 300px;
    opacity: 30%;
    position: relative;
}

.thumbnail:hover {
    opacity: 100%;
}

.thumbnail-desc {
    display: none;
}

.thumbnail:hover .thumbnail-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: .7rem;
    background-color: #E1F4FF;
    color: #001C2D;
}


.thumbnail-desc p {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
    .thumbnail {
        width: 90vw;
        height: auto;
        opacity: 100%;
        margin: 1rem .3rem 1rem .3rem;
    }
    .thumbnail-desc {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: .7rem;
        background-color: #E1F4FF;
        color: #001C2D;
    }
}

@media screen and (max-width: 480px) {

}

