.button-secondary {
    padding: 1rem;
    margin: .5rem;
    font-size: 18px;
    border-radius: 100px;
    border: 3px solid #FAFA33;
    min-width: 200px;
    background: none;
    font-weight: 700;
    color: #FAFAFA;
}

.button-secondary:hover {
    cursor: pointer;
    background:#FAFA33;
    border: 3px solid #FAFA33;
    color: #001C2D;
    box-shadow: 0 0 14px #FAFA3355;
}   

@media screen and (max-width: 480px) {
    .button-secondary {
        width: 100%,
    }
}
