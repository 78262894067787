.service-overview-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    width: 75%;
    padding: 4rem;
    margin: 0 0 3rem 0;
    background-color: #FFFFFF04;
    border-radius: 30px; 
}

.service-overview-container p {
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: #C2C2C2;

}

.open-cards-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 60px; /* adjust this value to add space between the cards */
    grid-column-gap: 30px;
    margin: 5rem 0 0 0;


}
.service-overview-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 2rem;
}

.service-overview-title h1 {
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.service-overview-icon {
    width: 80px;
    padding-right: 1rem;
}
