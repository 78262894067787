.skill-list-columns {
    height: auto;
}

.skill-list-columns h2 {
    font-size: 48px;  
    text-align: left;
}

.skill-list-columns ul {
    list-style-type: none;
    text-align: left;
    padding: 0;
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
}

.skill-list-columns li {
    display: flex;
    padding-bottom: 2.5rem;
    justify-content: left;
    align-items: center;
    width: 33.33%; /* Distribute items into 3 columns */
    box-sizing: border-box;
}

.skill-list-columns img {
    width: 60px;
    padding-right: 15px;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
    .skill-list-columns li {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {

}