/*Homepage*/

h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-section {
    padding-top: 4.5rem;
    height: auto;
}

.impressum-section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-top: 7.5rem;
    width: 70%;
    height: 80vh;
}

.impressum-section p {
    margin: 0 0 1rem 0;
    padding: 0;
}

.impressum-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 2rem;
}

.impressum-section .bold-text-color {
    color: #0093E9;
}
.headline {
    width: 800px;
}

.thumbnail-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.coming-soon {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF12;
    font-size: 1.5rem;
    margin: 1rem 0 5rem 0;
    width: 1200px;
    height: 300px;
    opacity: 30%;
}

.coming-soon p {
    margin: 0;
    padding: 0;
    font-size: 2rem;
    font-weight: 700;
}

.coming-soon .smaller-text {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 8px;
    width: 50ch;
}

.clients-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    background-color: #001929;
    background-image: url("../../src/assets/divider.png");
    background-repeat: no-repeat;
    background-position: top;
}

.clients-section h2 {
    font-size: 32px;
    padding: 0;
    margin: 0;
}

.clients-section h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    opacity: 70%;
}

.clients-section img {
    width: 700px;
    margin: 3rem 0 0 0;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {
    .headline {
        width: 700px;
    }
    .clients-section img {
        width: 700px;
        margin: 3rem 0 0 0;
    }
}

@media screen and (max-width: 768px) {
    .headline {
        width: 600px;
    }
    h1 {
        font-size: 2.4rem;
    }
    .coming-soon {
        margin: 0;
        width: 90vw;
        height: 300px;
    }
    .impressum-section {
        padding-top: 8rem;
        width: 90%;
        height: 70vh;
    }
    .clients-section img {
        width: 90%;
        margin: 3rem 0 0 0;
    }
}

@media screen and (max-width: 480px) {
    .headline {
        width: 95vw;
    }
    .clients-section {
        height: 70vh;
    }
    .clients-section img {
        width: 90%;
        margin: 3rem 0 0 0;
    }

}