.cta-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 22px;
  align-items: center;
  margin: 4rem 0 4rem 0;
}

.cta-section h2 {
  font-size: 48px;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1920px) {
  .cta-section h2 {
    font-size: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .cta-section h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  .cta-section h2 {
    font-size: 36px;
  }
  .cta-section {
    margin: 1rem 0 4rem 0;
  }
}

@media screen and (max-width: 480px) {
  .cta-section h2 {
    font-size: 24px;
  }
}
