.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 100;
  width: 100%;
  height: 50px;
  padding: 0 5rem;
  gap: 2rem;
  background-color: var(--navbar-color);
  color: #FFFFFF60;
}

.navbar a {
  color:inherit;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
}

.navbar .active {
  color: #0093E9;
}
.navbar a:hover {
  color:#FFFFFF95;
}

.navbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.talentic-logo {
  width: 150px;
}

nav .hamburger-menu {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  bottom:1.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .hamburger-menu span {
  height: 0.35rem;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 0.2rem;
}

@media screen and (max-width: 1920px) {

}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
  nav .hamburger-menu {
    display: flex;
  }

  .navbar {
    flex-direction: column;
    align-items: start;
    padding: 0;
    height: 70px;
    width: 100vw;
    background-color: var(--navbar-color);
  }

  .navbar ul {
    display: none;
    width: 100vw;
    background-color: var(--navbar-color);
    gap: 0;
    margin: 0;
  }

  .navbar ul.open {
    display: flex;
    flex-direction: column;
    margin-top: 16.5rem;
  }
  
  .navbar ul a {
    font-size: 20px;
    margin: 0;
    padding: 1.4rem 0;
  }

  .navbar ul :hover {
    background-color: #FFFFFF04;
  }

  .navbar a .talentic-logo {
    position: absolute;
    top: 10px;
    left: 2rem;
  }
}

@media screen and (max-width: 480px) {

}

